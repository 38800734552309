<template>
  <div fluid class="warehouse-detail" v-if="warehouse">
    <Breadcrumb :items="breadCrumbs" />
    <v-container fluid>
      <div class="mb-4 d-flex align-center justify-space-between">
        <h1>
          {{ warehouse.address.addressName }}, {{ warehouse.address.address1 }}
        </h1>
        <router-link
          class="ml-auto d-flex align-center 
           text-decoration-none"
          :to="{ name: 'WarehouseMap' }"
        >
          <v-icon class="mr-3" color="primary" large>$arrowBack</v-icon>
          <strong color="default" class="default--text">{{
            $t("warehouseDetail.goBack")
          }}</strong>
        </router-link>
      </div>
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-img cover class="w-100 fill-height" :src="staticMapSrc" alt="" />
        </v-col>

        <v-col cols="12" sm="6" md="4" class="py-4">
          <v-img
            contain
            width="90"
            height="auto"
            :src="`/img_layout/warehouse/logo-${language}/${logo}.png`"
            class="mb-6"
          />
          <div>
            <h5 class="my-0 font-weight-bold text-uppercase">
              {{ $t("warehouseDetail.information.address") }}
            </h5>
            <span>
              {{ warehouseAddress }}
            </span>
          </div>
          <div>
            <h5 class="mb-0 font-weight-bold text-uppercase">
              {{ $t("warehouseDetail.information.contact.title") }}
            </h5>
            <span v-if="telephone">
              <span class="mr-1 text-capitalize">{{
                $t("warehouseDetail.information.contact.telephone.telephone")
              }}</span>
              <a
                :aria-label="
                  `${$t(
                    'warehouseDetail.contact.telephone.label'
                  )} ${telephone}`
                "
                :title="
                  `${$t(
                    'warehouseDetail.contact.telephone.title'
                  )} ${telephone}`
                "
                class="text-caption default--text text-decoration-none"
                :href="telephone"
                >{{ telephone }}</a
              >
            </span>
            <span v-if="email">
              <span class="mr-1 text-capitalize">{{
                $t("warehouseDetail.contact.email.email")
              }}</span>
              <a
                :aria-label="
                  `${$t('warehouseDetail.contact.email.writeMail')} ${email}`
                "
                :title="
                  `${$t(
                    'warehouseDetail.contact.telephone.writeMail'
                  )} ${email}`
                "
                class="text-caption default--text text-decoration-none"
                :href="email"
                >{{ email }}</a
              >
            </span>
          </div>
          <div
            class="mt-auto sd-flex align-center"
            v-if="
              warehouse.warehouseId == cart?.user?.defaultStoreAddress.addressId
            "
          >
            <v-icon class="mr-2" color="success">$star</v-icon>
            <span class="font-weight-bold text-uppercase success--text">{{
              $t("warehouse.favouriteStore")
            }}</span>
          </div>
          <!-- <v-card elevation="0" class="h-100" :key="warehouse.warehouseId">
            <v-card-text class="pt-0">
              <div class="mb-6">
                <div class="warehouse-info-title">
                  {{ $t("storeLocator.information") }}
                </div>
                <div>
                  {{ formattedAddress(warehouse) }}
                </div>
              </div>
              <div v-if="email" class="mb-4">
                <div class="font-weight-bold">Email:</div>

                <a
                  :aria-label="`Scrivi una mail a ${email}`"
                  :title="`Scrivi una mail a ${email}`"
                  class="text-caption font-weight-bold primary--text text--lighten-1 text-decoration-none"
                  :href="'mailto:' + email"
                  >{{ email }}</a
                >
              </div>
              <v-spacer />
              <span class="warehouse-info-title mt-5 mb-4">
                {{ $t("storeLocator.openingTimes") }}
              </span>
              <WarehouseHours
                v-if="warehouse"
                :serviceHours="warehouse.serviceHours"
                class="mb-4"
              />

              <span class="warehouse-info-title mt-5 mb-4">Servizi</span>
              <div
                v-if="warehouse.warehouseClass"
                class="warehouse-classes d-flex align-center mb-4"
              >
                <template v-for="(wClass, index) in warehouse.warehouseClass">
                  <img
                    v-if="wClass.iconSource"
                    :key="index"
                    :src="wClass.iconSource"
                    :alt="wClass.name"
                    class="warehouse-class-img mr-4"
                  />
                </template>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="warehouse.address"
                title="Indicazioni stradali"
                class="text-caption font-weight-bold primary--text text-decoration-none"
                link
                text
                :href="
                  `https://www.google.com/maps/dir/?dir_action=navigate&api=1&destination=${warehouse.address.latitude},${warehouse.address.longitude}`
                "
                target="_blank"
              >
                {{ $t("storeLocator.googleAddress") }}
                <v-icon x-small color="primary">$chevronRight</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card> -->
        </v-col>
        <v-col class="grey pa-3 rounded-md" cols="12" md="4">
          <h5 class="my-0 text-uppercase font-weight-normal">
            {{ $t("warehouseHours.title") }}
          </h5>
          <WarehouseHours
            :serviceHours="warehouse.serviceHours"
          ></WarehouseHours>
        </v-col>
      </v-row>
      <ebsn-meta
        class="description"
        :target="warehouse"
        path="warehouse_detail.DESCRIPTION"
        tag="div"
        @click="clicked"
      ></ebsn-meta>
    </v-container>
    <v-container fluid class="grey lighten1">
      <WarehouseLeafletSlider
        v-for="leafletId in leafletsIds"
        :key="leafletId"
        :leafletId="leafletId"
        :warehouse="warehouse"
      />
    </v-container>
    <!--<v-container class="dpoejwofirehiu" :key="warehouse.warehouseId">
       <product-list-slider
        :layout="22"
        :warehouseId="warehouse.warehouseId"
        :promo="true"
        :shuffle="true"
        :limit="12"
        :title="$t('warehouseDetail.productsPromoTitle')"
        :description="$t('warehouseDetail.productsPromoDescr')"
      /> 
      <ProductSliderWrapper
        :id="index"
        :counter="sliderCounter"
        :layout="22"
        :warehouseId="warehouse.warehouseId"
        :title="subCategory.name"
        :category="category"
        :subCategory="subCategory"
        :showArrows="true"
        :showBullets="true"
        @updateSliderWrapperViewable="updateSliderWrapperViewable"
      /> 
      <ProductSliderWrapper
        :layout="22"
        :warehouseId="0"
        :promo="true"
        :shuffle="true"
        :limit="12"
        :title="$t('warehouseDetail.productsPromoTitle')"
        :description="$t('warehouseDetail.productsPromoDescr')"
      />
    </v-container>-->
  </div>
</template>
<style lang="scss">
.warehouse-detail {
  .warehouse-map {
    width: 100%;
  }
  .warehouse-info-title {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 8px;
  }
}
</style>
<script>
import Breadcrumb from "@/components/navigation/Breadcrumb";
import WarehouseHours from "@/components/store/WarehouseHours.vue";
import WarehouseLeafletSlider from "@/components/store/WarehouseLeafletSlider.vue";
//import LeafletProductSlider from "@/components/categoryBlock/elements/LeafletProductSlider.vue";
//import WarehouseHours from "@/components/store/WarehouseHours.vue";
//import ProductSliderWrapper from "@/components/product/ProductSliderWrapper.vue";
import { calculateBreadcrumbs } from "@/components/store/warehouseHelper";
import AddressService from "~/service/addressService";
import clickHandler from "@/commons/mixins/clickHandler";
import { gmapApi } from "vue2-google-maps";
import leafletService from "@/commons/service/leafletService";
import { mapGetters, mapState } from "vuex";
export default {
  name: "WarehouseDetail",
  mixins: [clickHandler],
  components: {
    Breadcrumb,
    WarehouseHours,
    WarehouseLeafletSlider
    // WarehouseHours
    // ProductSliderWrapper
    // ProductListSlider
  },
  data() {
    return {
      warehouseId: null,
      warehouseSlug: null,
      warehouse: {},
      warehouseLeafletTypes: null,
      leafletsIds: null
    };
  },
  computed: {
    google: gmapApi,
    ...mapGetters({
      user: "cart/getUser",
      language: "app/getLanguage"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    staticMapSrc() {
      // example url to compose
      // https://maps.googleapis.com/maps/api/staticmap?center=45.482934,9.158543&zoom=10&key=AIzaSyBAQCkIX0VQBb1Dg6jFjYrO8t-lA12mhy0&size=640x400&maptype=roadmap&markers=icon:https://www.cooptrentino.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.482934,9.158543&markers=icon:https://www.cooptrentino.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.451084,9.096755&markers=icon:https://www.cooptrentino.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.498495,9.236599&markers=icon:https://www.cooptrentino.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.468506,9.041534&markers=icon:https://www.cooptrentino.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.546263,8.879139&markers=icon:https://www.cooptrentino.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.550804,8.944681&markers=icon:https://www.cooptrentino.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.529814,9.217539&markers=icon:https://www.cooptrentino.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.448217,9.212212&markers=icon:https://www.cooptrentino.it/p/wp-content/themes/ccno/img/markers/CoopLombardia.png|45.529916,9.506116
      let mapSrc = "https://maps.googleapis.com/maps/api/staticmap?";
      mapSrc += `center=${this.warehouse.address.latitude},${this.warehouse.address.longitude}`;
      mapSrc += "&zoom=10";
      mapSrc += `&key=${process.env.VUE_APP_GOOGLE_API_KEY}`;
      mapSrc += "&size=640x400";
      mapSrc += "&maptype=roadmap";

      if (
        this.warehouse.address &&
        this.warehouse.address.latitude &&
        this.warehouse.address.longitude
      ) {
        mapSrc += `&markers=icon:/img_layout/warehouse/pin.svg|${this.warehouse.address.latitude},${this.warehouse.address.longitude}`;
        // mapSrc += `&markers=icon:https://www.cooptrentino.it/img_layout/store_locator/pointer/lombardia.png|${warehouse.address.latitude},${warehouse.address.longitude}`;
      }
      return mapSrc;
    },
    // mapWidth() {
    //   switch (this.$vuetify.breakpoint.name) {
    //     case "xs":
    //       return "351px";
    //     case "sm":
    //       return "573px";
    //     case "md":
    //       return "563px";
    //     case "lg":
    //       return "754px";
    //     case "xl":
    //       return "1061px";
    //   }
    //   return "850px";
    // },
    telephone() {
      return this.$ebsn.meta(
        this.warehouse,
        "warehouse_detail.PHONE_NUMBER",
        null
      );
    },
    email() {
      return this.$ebsn.meta(this.warehouse, "warehouse_detail.EMAIL", null);
    },
    logo() {
      return this.$ebsn.meta(this.warehouse, "warehouse_detail.LOGO", null);
    },
    // mapHeight() {
    //   return "320px";
    // },
    breadCrumbs() {
      return calculateBreadcrumbs(this.$route.fullPath.substring(1));
    },
    // email() {
    //   return this.$ebsn.meta(this.warehouse, "warehouse_info.EMAIL", null);
    // },
    warehouseName() {
      return this.$ebsn.meta(this.warehouse, "warehouse_detail.NAME", null);
    },
    warehouseAddress() {
      const address = this.warehouse && this.warehouse.address;
      return `${address.address1}, ${address.addressNumber}, ${address.city}, ${address.province}, ${address.postalcode}`;
    }

    // filteredAddressClasses() {
    //   if (this.warehouse.address && this.warehouse.address.addressClass) {
    //     return this.warehouse.address.addressClass.filter(item => {
    //       return !(
    //         item.iconSource === null || item.iconSource.trim().length === 0
    //       );
    //     });
    //   } else {
    //     return [];
    //   }
    // }
  },
  methods: {
    formattedAddress(warehouse) {
      if (warehouse && warehouse.address) {
        return `${warehouse.address.address1}, ${warehouse.address.city} - (${warehouse.address.province}) ${warehouse.address.postalcode}`;
      }
    },
    async reload() {
      let data = await AddressService.findWarehouse({
        parent_warehouse_slug: this.warehouseSlug
      });
      if (data && data.warehouses && data.warehouses.length > 0) {
        this.warehouse = data.warehouses[0];
      }
    }
  },
  async mounted() {
    this.warehouseSlug = this.$route.params.slug;
    console.log("SLUG", this.$route.params.slug);
    await this.reload();

    this.warehouseLeafletTypes = this.$ebsn.meta(
      this.warehouse,
      "warehouse_detail.LEAFLET_TYPE",
      null
    );

    let leafletsIds = [];
    for (const leafletType of this.warehouseLeafletTypes) {
      const res = await leafletService.search({
        parent_leaflet_type_id: leafletType
      });
      if (res.length > 0) {
        leafletsIds.push(...res.map(leaflet => leaflet.leafletId));
      }
    }
    this.leafletsIds = [...new Set(leafletsIds)];
  },
  watch: {
    "$route.params.warehouseSlug"(value) {
      this.warehouseSlug = value;
      this.reload();
    }
  }
};
</script>

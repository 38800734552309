var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.warehouse)?_c('div',{staticClass:"warehouse-detail",attrs:{"fluid":""}},[_c('Breadcrumb',{attrs:{"items":_vm.breadCrumbs}}),_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"mb-4 d-flex align-center justify-space-between"},[_c('h1',[_vm._v(" "+_vm._s(_vm.warehouse.address.addressName)+", "+_vm._s(_vm.warehouse.address.address1)+" ")]),_c('router-link',{staticClass:"ml-auto d-flex align-center text-decoration-none",attrs:{"to":{ name: 'WarehouseMap' }}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"primary","large":""}},[_vm._v("$arrowBack")]),_c('strong',{staticClass:"default--text",attrs:{"color":"default"}},[_vm._v(_vm._s(_vm.$t("warehouseDetail.goBack")))])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-img',{staticClass:"w-100 fill-height",attrs:{"cover":"","src":_vm.staticMapSrc,"alt":""}})],1),_c('v-col',{staticClass:"py-4",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-img',{staticClass:"mb-6",attrs:{"contain":"","width":"90","height":"auto","src":`/img_layout/warehouse/logo-${_vm.language}/${_vm.logo}.png`}}),_c('div',[_c('h5',{staticClass:"my-0 font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("warehouseDetail.information.address"))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.warehouseAddress)+" ")])]),_c('div',[_c('h5',{staticClass:"mb-0 font-weight-bold text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t("warehouseDetail.information.contact.title"))+" ")]),(_vm.telephone)?_c('span',[_c('span',{staticClass:"mr-1 text-capitalize"},[_vm._v(_vm._s(_vm.$t("warehouseDetail.information.contact.telephone.telephone")))]),_c('a',{staticClass:"text-caption default--text text-decoration-none",attrs:{"aria-label":`${_vm.$t(
                  'warehouseDetail.contact.telephone.label'
                )} ${_vm.telephone}`,"title":`${_vm.$t(
                  'warehouseDetail.contact.telephone.title'
                )} ${_vm.telephone}`,"href":_vm.telephone}},[_vm._v(_vm._s(_vm.telephone))])]):_vm._e(),(_vm.email)?_c('span',[_c('span',{staticClass:"mr-1 text-capitalize"},[_vm._v(_vm._s(_vm.$t("warehouseDetail.contact.email.email")))]),_c('a',{staticClass:"text-caption default--text text-decoration-none",attrs:{"aria-label":`${_vm.$t('warehouseDetail.contact.email.writeMail')} ${_vm.email}`,"title":`${_vm.$t(
                  'warehouseDetail.contact.telephone.writeMail'
                )} ${_vm.email}`,"href":_vm.email}},[_vm._v(_vm._s(_vm.email))])]):_vm._e()]),(
            _vm.warehouse.warehouseId == _vm.cart?.user?.defaultStoreAddress.addressId
          )?_c('div',{staticClass:"mt-auto sd-flex align-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"}},[_vm._v("$star")]),_c('span',{staticClass:"font-weight-bold text-uppercase success--text"},[_vm._v(_vm._s(_vm.$t("warehouse.favouriteStore")))])],1):_vm._e()],1),_c('v-col',{staticClass:"grey pa-3 rounded-md",attrs:{"cols":"12","md":"4"}},[_c('h5',{staticClass:"my-0 text-uppercase font-weight-normal"},[_vm._v(" "+_vm._s(_vm.$t("warehouseHours.title"))+" ")]),_c('WarehouseHours',{attrs:{"serviceHours":_vm.warehouse.serviceHours}})],1)],1),_c('ebsn-meta',{staticClass:"description",attrs:{"target":_vm.warehouse,"path":"warehouse_detail.DESCRIPTION","tag":"div"},on:{"click":_vm.clicked}})],1),_c('v-container',{staticClass:"grey lighten1",attrs:{"fluid":""}},_vm._l((_vm.leafletsIds),function(leafletId){return _c('WarehouseLeafletSlider',{key:leafletId,attrs:{"leafletId":leafletId,"warehouse":_vm.warehouse}})}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
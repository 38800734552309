var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.leaflet)?_c('div',[_c('h5',{staticClass:"font-weight-bold primary--text text-uppercase mb-0"},[_vm._v(" "+_vm._s(_vm.$t("warehouseDetail.pdvLeaflet.title"))+" ")]),_c('h2',{staticClass:"default--text mb-6 mt-0"},[_vm._v(" "+_vm._s(_vm.$t("warehouseDetail.pdvLeaflet.address"))+" "+_vm._s(_vm.warehouse.address.addressName)+", "+_vm._s(_vm.warehouse.address.address1)+" ")]),_c('v-row',{staticClass:"flex-nowrap"},[_c('swiper',{ref:"swiperRef",staticClass:"ml-0 py-3",attrs:{"options":_vm.swiperOption}},[_c('swiper-slide',[_c('v-col',{staticClass:"fill-height",attrs:{"cols":"4"}},[_c('v-card',{staticClass:"fill-height d-flex flex-column justify-center align-center leaflet-card rounded-md elevation-3 pa-3",attrs:{"to":{
              name: 'Leaflet',
              params: {
                leafletId: _vm.leaflet.slug
                  ? _vm.leaflet.slug
                  : _vm.leaflet.leafletId.toString()
              }
            }}},[_c('v-img',{staticClass:"mb-5 leaflet-card__image",staticStyle:{"width":"90%","height":"auto"},attrs:{"contain":"","src":`${_vm.leaflet.baseLocation}${_vm.leaflet.leafletPage[0].imageLocation}`}}),_c('v-btn',{staticClass:"w-100 elevation-0 rounded-pill",attrs:{"large":"","color":"primary"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("leaflet.navigation.discover")))])])],1)],1)],1),(_vm.products && _vm.products.length > 0)?[_c('swiper-slide',{staticClass:"d-flex align-center slider-in-pdv"},_vm._l((_vm.products),function(product,index){return _c('v-col',{key:product.productId,staticClass:"slider-in-pdv__item fill-height",attrs:{"cols":"4"}},[_c('ProductCardLeaflet',{key:product.productId,attrs:{"product":product,"leaflet":_vm.leaflet,"position":_vm.initPosition + index},on:{"selectProduct":function($event){return _vm.$emit('selectProduct', {
                  product: product,
                  index: _vm.initPosition + index
                })}}})],1)}),1)]:_vm._e()],2),(_vm.products)?_c('swiper-paginator',{attrs:{"showBullets":true,"showArrows":false,"paginationClass":`leaflet-${_vm.leafletId}`,"length":_vm.products.length}}):_vm._e()],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-list-group class="warehouse-hours" value="true">
    <v-list-item class="px-0">
      <v-list-item-title class="d-flex opening-day-title" v-if="todayHours">
        <span class="text-capitalize week-day font-weight-bold">{{
          todayHours.weekDay
        }}</span>
        <v-spacer />
        <span
          class="font-weight-bold hours"
          v-if="todayHours.hours"
          v-html="todayHours.hours"
        ></span>
      </v-list-item-title>
    </v-list-item>
    <div v-if="otherDayHours && otherDayHours.length > 0">
      <v-list-item v-for="day in otherDayHours" :key="day.weekDay" class="px-0">
        <v-list-item-title class="d-flex opening-day-title">
          <span class="text-capitalize week-day">{{ day.weekDay }}</span>
          <v-spacer />
          <span class="hours" v-if="day.hours" v-html="day.hours"></span>
        </v-list-item-title>
      </v-list-item>
    </div>
  </v-list-group>
</template>
<style lang="scss">
.warehouse-hours {
  .v-list-item {
    min-height: 30px;
    padding: 5px 0;
    &:not(:last-child) {
      border-bottom: 1px solid var(--v-grey-darken1);
    }
  }
  .v-list-group__header {
    padding: 0;
    display: none;
  }
}
</style>
<script>
import { calculateHourRow } from "@/components/store/warehouseHelper";

export default {
  name: "WarehouseHours",
  props: {
    serviceHours: { type: Object, required: true }
  },
  data() {
    return {
      todayHours: null,
      otherDayHours: [],
      offsetDay: 1
    };
  },
  methods: {},
  mounted() {
    // day of week from server: 1 sunday, 7 saturday
    // dayJs: 0 sunday, 6 saturday
    let today = this.$dayjs();
    let todayHours = calculateHourRow(this.serviceHours, today, this.offsetDay);

    this.todayHours = {
      weekDay: today.format("dddd"),
      hours: todayHours ? todayHours : ""
    };

    for (let i = 0; i < 6; i++) {
      let dayToCalc = this.$dayjs().add(i + 1, "day");
      let otherDay = calculateHourRow(
        this.serviceHours,
        dayToCalc,
        this.offsetDay
      );
      this.otherDayHours.push({
        weekDay: dayToCalc.format("dddd"),
        hours: otherDay ? otherDay : ""
      });
    }
  }
};
</script>

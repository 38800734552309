<template>
  <div v-if="leaflet">
    <h5 class="font-weight-bold primary--text text-uppercase mb-0">
      {{ $t("warehouseDetail.pdvLeaflet.title") }}
    </h5>
    <h2 class="default--text mb-6 mt-0">
      {{ $t("warehouseDetail.pdvLeaflet.address") }}
      {{ warehouse.address.addressName }}, {{ warehouse.address.address1 }}
    </h2>

    <v-row class="flex-nowrap">
      <swiper :options="swiperOption" ref="swiperRef" class="ml-0 py-3">
        <swiper-slide>
          <v-col cols="4" class="fill-height">
            <v-card
              class="fill-height d-flex flex-column justify-center align-center leaflet-card rounded-md elevation-3 pa-3"
              :to="{
                name: 'Leaflet',
                params: {
                  leafletId: leaflet.slug
                    ? leaflet.slug
                    : leaflet.leafletId.toString()
                }
              }"
            >
              <v-img
                contain
                style="width: 90%; height: auto"
                class="mb-5 leaflet-card__image"
                :src="
                  `${leaflet.baseLocation}${leaflet.leafletPage[0].imageLocation}`
                "
              />

              <v-btn
                large
                class="w-100 elevation-0 rounded-pill"
                color="primary"
              >
                <span class="font-weight-bold">{{
                  $t("leaflet.navigation.discover")
                }}</span>
              </v-btn>
            </v-card>
          </v-col>
        </swiper-slide>
        <template v-if="products && products.length > 0">
          <swiper-slide class="d-flex align-center slider-in-pdv">
            <v-col
              cols="4"
              class="slider-in-pdv__item fill-height"
              v-for="(product, index) in products"
              :key="product.productId"
            >
              <ProductCardLeaflet
                :key="product.productId"
                v-bind:product="product"
                :leaflet="leaflet"
                :position="initPosition + index"
                @selectProduct="
                  $emit('selectProduct', {
                    product: product,
                    index: initPosition + index
                  })
                "
              />
            </v-col>
          </swiper-slide>
        </template>
      </swiper>
      <swiper-paginator
        v-if="products"
        :showBullets="true"
        :showArrows="false"
        :paginationClass="`leaflet-${leafletId}`"
        :length="products.length"
      />
    </v-row>
  </div>
</template>
<style global lang="scss">
.slider-in-pdv {
  &__item {
    max-width: 270px;
    min-width: 270px;
    width: 270px;
  }
  .product-card-leaflet {
    //width: 100%;
    // max-width: 270px;
  }
}
.leaflet-card {
  width: 270px;
  max-width: 270px;
  min-height: 375px;
}
.ecommerce .product-list-slider .product-slider-card {
  height: 100%;
  border: 0;
}
.product-list-slider {
  .product-slider-card {
    width: 100%;
    // height: $default-card-height;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .swiper-slide {
    width: 280px !important;
    height: auto;
  }
}
</style>
<script>
import ProductCardLeaflet from "@/components/leaflet/ProductCardLeaflet.vue";
import SwiperPaginator from "@/components/SwiperPaginator.vue";
//import analyticsService from "~/service/analyticsService";
import leafletService from "~/service/leafletService";

export default {
  name: "WarehouseLeafletSlider",
  props: {
    showMore: { type: Boolean, default: false },
    initPosition: { type: Number, default: 0 },
    leafletId: { type: Number, required: true },
    warehouse: { type: Object, required: false }
  },
  components: {
    ProductCardLeaflet,
    SwiperPaginator
  },

  data() {
    return {
      name: this.categoryBlockName,
      animateLink: false,
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        // breakpoints: {
        //   0: {
        //     slidesPerView: 2
        //   },
        //   600: {
        //     slidesPerView: 2
        //   },
        //   960: {
        //     slidesPerView: 3
        //   },
        //   1264: {
        //     slidesPerView: 4
        //   },
        //   1900: {
        //     slidesPerView: 6
        //   }
        // },
        pagination: {
          clickable: true,
          el: ".swiper-pagination"
        }
      },
      leaflet: null,
      imgUrl: null,
      products: null
    };
  },
  computed: {},
  methods: {
    // handleView(entries, observer, isIntersecting) {
    //   this.$emit("updateSliderWrapperViewable");
    //   if (isIntersecting && this.proposal?.length > 0) {
    //     analyticsService.viewPromotions(
    //       [this.proposal],
    //       this.position,
    //       this.products
    //     );
    //   }
    // },
    handleClick() {},
    async fetchProducts(pageNumber = 1) {
      let response = await leafletService.searchProducts({
        parent_leaflet_id: this.leafletId,
        parent_leaflet_page_number_id: pageNumber
      });
      if (response) {
        this.products = response.data.products;
      }
    },
    async fetchLeaflet(leafletId) {
      if (leafletId) {
        this.leaflet = await leafletService.getLeafletById(leafletId);
        await this.fetchProducts();
      }
    }
  },
  async created() {
    this.leafletId = await this.fetchLeaflet(this.leafletId);
  }
};
</script>
